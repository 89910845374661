import styled from 'styled-components';
import { animated } from 'react-spring';

const Main = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

Main.Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 5px 5px 5px;
  height: 100%;
  width: 100%;
  position: absolute;
`;

Main.Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  h2 {
    font-size: 22px;
    font-weight: bold;
    color: #2c405a;
    display: flex;
    justify-content: center;
  }
`;

Main.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px;
  overflow-y: auto;
`;

Main.Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
`;

const Card = styled(animated.div)`
  position: relative;
  display: flex;
  background: #fff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin: 15px;
  min-height: 50px;
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }

  > a {
    display: block;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: inherit;
    text-decoration: none;
    text-align: center;
    height: 100%;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 5px;

    /* :hover {
      border: 1px solid #ccc;
    } */
  }
`;

Card.Wrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
`;

Card.Language = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  color: #2c405a;
  border-radius: 5px;
  font-size: 10px;
`;

Card.Title = styled.h4`
  margin: 10px 0;
  color: #2c405a;
`;

Card.Image = styled.img`
  border-radius: 10px;
  height: 90%;
  width: 90%;
  object-fit: cover;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
`;

Card.Content = styled.span`
  color: #555;
  font-size: 14px;
  margin: 5px 0;
`;

Card.Maintenance = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  cursor: not-allowed;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 40px;
`;

Card.Maintenance.Content = styled.span`
  display: grid;
  grid-template-columns: 18px 1fr;
  gap: 2px;
  background-color: rgba(255, 255, 255, 0.92);
  color: #de3b3b;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
`;

const HandleMenu = styled.button`
  border: 1px solid red;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 10px;
  z-index: 99;
  border: 2px solid #79869e;
  border-radius: 50%;
  color: #79869e;
  background-color: transparent;
`;

export { Main, Card, HandleMenu };
