import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  MdChevronLeft,
  MdChevronRight,
  MdClose,
  MdHome,
  MdStarOutline,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdListAlt,
} from 'react-icons/md';

import { components } from 'react-select';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import { opTipoAcao, opTipoAcesso } from '../../../../lib/const';
import { debounce } from '../../../../lib/inputUtils';
import { initialParams } from '../../../../lib/reactRouter';
import { getMaterial, getArranjo } from '../../../../lib/asyncUtils';

import TreeView from '../../../../components/TreeView';

import { Form } from '../../../../components/Form';
import AsyncSelect from '../../../../components/Form/Input/AsyncSelect';
// import Info from '../../../../components/Info';
import Modal from '../../../../components/Modal';

import { MobileListagem } from './Listagem';
import { MobilePagina } from './Pagina';

import {
  Main,
  Container,
  BtnTreeView,
  Menu,
  Content,
  WrapperTitleInfo,
  WrapperSubtitles,
  WrapperTittleInfoSubtitle,
  ContentToolBarHeaderButton,
} from './styles';

export function MobileClassificacao() {
  const { id } = useParams();
  const location = useLocation();

  const { action, signed } = useSelector((state) => state.auth || {});
  const { adm } = useSelector((state) => state.user.profile || {});

  /** referencia para atribuir controles de zoom */
  const zoomInRef = useRef();
  const zoomOutRef = useRef();
  const zoomResetRef = useRef();

  const formRef = useRef();
  const paginaRef = useRef();

  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);

  const [menuHidden, setMenuHidden] = useState(false);
  // const [pageMaximize, setPageMaximize] = useState(false);

  const [catalogo, setCatalogo] = useState(null);
  const [paginaCatalogo, setPaginaCatalogo] = useState([]);
  const [controle, setControle] = useState(false);
  const [shownPage, setShownPage] = useState(0);
  // const [nSerie, setNSerie] = useState(null);

  const handleTreeView = useCallback(() => {
    setMenuHidden((state) => !state);
  }, []);

  useEffect(() => {
    api.get(`/catalogo-navegacao/${id}/pagina`).then((response) => {
      setPaginaCatalogo(response.data);
    });
  }, [id]);

  useEffect(() => {
    /** pega parâmetros da rota */
    const paramQuery = new URLSearchParams(location.search);

    const paramRoute = {
      ...initialParams,
      ...Object.fromEntries(paramQuery),
    };

    api
      .get(`/catalogo-navegacao/${id}`, {
        params: {
          ...paramRoute,
        },
      })
      .then((response) => {
        setCatalogo(response.data);

        /** Preenchimento do campos arranjo */
        const { arranjo } = response.data;

        if (arranjo && formRef.current) {
          const valorAtual = formRef.current.getFieldValue('idArranjo');
          if (!valorAtual) {
            formRef.current.setFieldValue('idArranjo', {
              label: `N/S: ${arranjo.numeroSerie} - ${arranjo.nome}`,
              value: `${arranjo.idSerie}.${arranjo.idArranjo}`,
            });
          }
        }
      })
      .catch((err) => {
        AppError(err);
      });
  }, [id, location]);

  useEffect(() => {
    /** pega parâmetros da rota */
    const paramQuery = new URLSearchParams(location.search);

    setControle(paramQuery.has('IDP'));
    if (catalogo && paramQuery.has('IDP')) {
      const page = Number(paramQuery.get('IDP'));

      const { paginas = [] } = catalogo;

      const index = paginas.findIndex((p) => p === page);
      if (index >= 0) setShownPage(index + 1);

      setMenuHidden(false);
    }
  }, [location, catalogo]);

  const handlePagina = useCallback(
    (data) => {
      const { value = '' } = data || {};

      const [, idPagina] = value.split('.');

      if (idPagina) {
        const { niveis = [] } =
          paginaCatalogo.find((p) => p.idPagina === Number(idPagina)) || {};

        const searchLocation = [];

        /** processa os níveis da página */
        niveis.forEach((itemNivel) => {
          searchLocation.push(`IDN_${itemNivel.codigo}=${itemNivel.id}`);
        });

        /** processa página */
        searchLocation.push(`IDP=${idPagina}`);

        searchLocation.push(`IDI=${data.value}`);

        /** monta rota para redirecionamento */
        history.push(`${id}?${searchLocation.join('&')}`);
      }
    },
    [id, paginaCatalogo]
  );

  const handleSerieArranjo = useCallback(
    (data) => {
      const params = [];

      /** separa valores "serie.arranjo" */
      const { value = '' } = data || {};
      const [serie, arranjo] = value.split('.');

      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);
      const paramRoute = {
        ...initialParams,
        ...Object.fromEntries(paramQuery),
      };

      /** processa os parâmetros de sistema e local caso eles existam no primeiro ciclo */
      const { FLT_IDS: fltIdS, FLT_IDL: fltIdL } = paramRoute;
      if (fltIdS) params.push(`FLT_IDS=${fltIdS}`);
      if (fltIdL) params.push(`FLT_IDL=${fltIdL}`);

      /** processa arranjo */
      if (serie && arranjo) {
        params.push(`FLT_IDE=${serie}`);
        params.push(`FLT_IDA=${arranjo}`);
      }

      /** monta rota para redirecionamento */
      const routeParam = `?${params.join('&')}`;

      if (location.search !== routeParam)
        history.push(location.pathname + routeParam);
    },
    [location]
  );

  const handlePageNav = useCallback(
    (step) => {
      if (catalogo && step !== 0) {
        const nextPage = shownPage - 1 + step;

        /** Navega para a página encontrada */
        if (nextPage >= 0 && nextPage < paginaCatalogo.length) {
          const { idPagina, niveis } = paginaCatalogo[nextPage];

          const { idCatalogo, paginas = [] } = catalogo;

          /** verifica se a página não está filtrada */
          if (paginas.find((p) => p === idPagina)) {
            const searchLocation = [];

            /** pega parâmetros da rota */
            const paramQuery = new URLSearchParams(location.search);
            const paramRoute = {
              ...initialParams,
              ...Object.fromEntries(paramQuery),
            };

            /** processa os parâmetros de sistema, local e pesquisa caso eles existam no primeiro ciclo */
            const {
              FLT_IDS: fltIdS,
              FLT_IDL: fltIdL,
              FLT_IDE: fltIdE,
              FLT_IDA: fltIdA,
              q: fltSearch,
            } = paramRoute;
            if (fltIdS) searchLocation.push(`FLT_IDS=${fltIdS}`);
            if (fltIdL) searchLocation.push(`FLT_IDL=${fltIdL}`);
            if (fltIdE) searchLocation.push(`FLT_IDE=${fltIdE}`);
            if (fltIdA) searchLocation.push(`FLT_IDA=${fltIdA}`);
            if (fltSearch) searchLocation.push(`q=${fltSearch}`);

            /** processa os níveis da página */
            niveis.forEach((itemNivel) => {
              searchLocation.push(`IDN_${itemNivel.codigo}=${itemNivel.id}`);
            });

            /** processa página */
            searchLocation.push(`IDP=${idPagina}`);

            /** monta rota para redirecionamento */
            history.push(`${idCatalogo}?${searchLocation.join('&')}`);
          }
        }
      }
    },
    [location, catalogo, paginaCatalogo, shownPage]
  );

  const handleFiltro = useCallback(
    (key) => {
      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);

      const params = [];
      paramQuery.forEach((param, index) => {
        if (index !== key) {
          params.push(`${index}=${param}`);
        }
      });

      const routeParam = `?${params.join('&')}`;

      history.push(location.pathname + routeParam);
    },
    [location]
  );

  const handleShowBreadcrumbs = (state) => {
    setShowBreadcrumbs(!state);
  };

  const handleBreadcrumb = useCallback(
    (data) => {
      const breadcrumbs = [];
      const url = [];

      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);
      const paramRoute = {
        ...initialParams,
        ...Object.fromEntries(paramQuery),
      };

      /** processa os parâmetros de sistema, local e pesquisa caso eles existam */
      const {
        FLT_IDS: fltIdS,
        FLT_IDL: fltIdL,
        FLT_IDE: fltIdE,
        FLT_IDA: fltIdA,
        q: fltSearch,
      } = paramRoute;
      if (fltIdS) url.push(`FLT_IDS=${fltIdS}`);
      if (fltIdL) url.push(`FLT_IDL=${fltIdL}`);
      if (fltIdE) url.push(`FLT_IDE=${fltIdE}`);
      if (fltIdA) url.push(`FLT_IDA=${fltIdA}`);
      if (fltSearch) url.push(`q=${fltSearch}`);

      const { idCatalogo, nome, treeView } = data;

      breadcrumbs.push({
        name: nome,
        url:
          url.length > 0 ? `${idCatalogo}?${url.join('&')}` : `${idCatalogo}`,
      });

      let currNode = treeView;

      Object.keys(paramRoute).forEach((paramAtual) => {
        /** monta breadcrumb */
        if (paramAtual.startsWith('IDN_') || paramAtual.startsWith('IDP')) {
          const [, key] = paramAtual.split('_');

          /** monta url para acesso */
          url.push(`${paramAtual}=${paramRoute[paramAtual]}`);

          const itemNode = currNode.find(
            (n) =>
              n.codigo === (Number(key) || idCatalogo) &&
              n.id === Number(paramRoute[paramAtual])
          );

          if (itemNode) {
            const { descricao } = itemNode;

            breadcrumbs.push({
              name: descricao,
              url: `?${url.join('&')}`,
            });

            /** atualiza item da treeview */
            if (itemNode.itens) currNode = itemNode.itens;
          }
        }
      });

      const { name = '' } = breadcrumbs[breadcrumbs.length - 1] || {};

      return (
        <Content.ToolBar.Header>
          <h3>{name}</h3>
          {breadcrumbs.map((bread, index, array) => {
            const label =
              array.length - 1 === index ? bread.name : `${bread.name} / `;

            return (
              <Link key={index} to={bread.url}>
                {label}
              </Link>
            );
          })}
        </Content.ToolBar.Header>
      );
    },
    [location]
  );

  const handleContainer = useCallback(
    (data) => {
      const { idCatalogo } = data;

      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);

      return paramQuery.has('IDP') ? (
        <MobilePagina
          ref={paginaRef}
          id={idCatalogo}
          idPagina={Number(paramQuery.get('IDP'))}
          zoomInRef={zoomInRef}
          zoomOutRef={zoomOutRef}
          zoomResetRef={zoomResetRef}
        />
      ) : (
        <MobileListagem data={data} />
      );
    },
    [location]
  );

  const Group = (props) => <components.Group {...props} />;

  return (
    <Container>
      {catalogo && (
        <>
          <Container.Header>
            <WrapperTittleInfoSubtitle>
              {[opTipoAcao.PLATAFORMA, opTipoAcao.INTEGRACAO_CONSULTA].includes(
                action
              ) && (
                <Container.Header.BtnBack
                  title="Voltar para a Home"
                  onClick={() => history.push('/')}
                >
                  <MdHome size={25} />
                </Container.Header.BtnBack>
              )}
              <WrapperTitleInfo>
                <Container.Header.Title>{catalogo.nome}</Container.Header.Title>
              </WrapperTitleInfo>
              <WrapperSubtitles>
                <Container.Header.Subtitle>
                  {catalogo.arquivo.fabricante.descricao}
                </Container.Header.Subtitle>
                <Container.Header.Subtitle>-</Container.Header.Subtitle>
                <Container.Header.Subtitle>
                  {catalogo.arquivo.modelos
                    .map((item) => item.descricao)
                    .join(' | ')}
                </Container.Header.Subtitle>
              </WrapperSubtitles>
            </WrapperTittleInfoSubtitle>

            {[opTipoAcao.INTEGRACAO_CATALOGO].includes(action) &&
              [opTipoAcesso.CLIENTE].includes(Number(adm)) &&
              signed && (
                <Container.Header.BtnFavorite title="Solicitar ao fornecedor para manter o acesso ao catálogo">
                  <MdStarOutline size={25} />
                </Container.Header.BtnFavorite>
              )}
            <Container.Header.Search>
              <Form ref={formRef}>
                <Form.Row>
                  {catalogo.arranjoPagina && (
                    <AsyncSelect
                      id="idArranjo"
                      name="idArranjo"
                      placeholder="Digite para prequisar um arranjo..."
                      isClearable
                      cacheOptions
                      defaultOptions
                      loadOptions={(value, cb) => getArranjo({ id, value }, cb)}
                      onChange={(data) => handleSerieArranjo(data)}
                      width={270}
                    />
                  )}
                  <AsyncSelect
                    id="idMaterial"
                    name="idMaterial"
                    placeholder="Digite um termo, partnumber ou código ERP para pesquisar..."
                    isClearable
                    cacheOptions
                    defaultOptions
                    loadOptions={(value, cb) => getMaterial({ id, value }, cb)}
                    component={{ Group }}
                    onChange={(data) => handlePagina(data)}
                    formatOptionLabel={(option, { context }) => {
                      const imageUrl = option.label.caminho;

                      // Apenas se o Select estiver aberto (context === 'menu')
                      return (
                        <div
                          // className="hover-container-async-select"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          {context === 'menu' ? (
                            <>
                              <div className="hover-container-async-select">
                                <img
                                  id="img-async-select"
                                  src={imageUrl}
                                  alt={option.label.descricaoPagina}
                                />
                                <img
                                  className="hover-image-async-select"
                                  src={imageUrl}
                                  alt={option.label.descricaoPagina}
                                />
                              </div>
                              <div>
                                <strong id="grey-bold-async-select">
                                  {option.label.partnumber ||
                                    option.label.codimate}{' '}
                                  - {option.label.descricao}{' '}
                                </strong>
                                <div id="grey-italic-async-select">
                                  {option.label.descricaoPagina}{' '}
                                  <strong id="black-bold-async-select">
                                    {' '}
                                    |{' '}
                                  </strong>
                                  {option.label.fmtPaginaCatalogoNivel}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <strong id="grey-bold-async-select">
                                {option.label.partnumber ||
                                  option.label.codimate}{' '}
                                - {option.label.descricao}
                                <strong id="black-bold-async-select">
                                  {' '}
                                  |{' '}
                                </strong>
                              </strong>
                              <span id="grey-italic-async-select">
                                {option.label.descricaoPagina}{' '}
                                <strong id="black-bold-async-select">
                                  {' '}
                                  |{' '}
                                </strong>
                                {option.label.fmtPaginaCatalogoNivel}
                              </span>
                            </>
                          )}
                        </div>
                      );
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: 40,
                        // borderRadius: '5px 0 0 5px',
                      }),
                      menu: (base) => ({
                        ...base,
                        // width: 420,
                        // minHeight: 550,
                      }),
                      menuList: (base) => ({
                        ...base,
                        minHeight: 400,
                        maxHeight: 500,
                      }),
                    }}
                    width={450}
                  />
                </Form.Row>
              </Form>
            </Container.Header.Search>
          </Container.Header>

          {menuHidden && (
            <Modal width={95} height={95} onClose={handleTreeView}>
              <Menu.Content.TreeView>
                <Menu.Content.Filter>
                  {catalogo.sistema && (
                    <span>
                      {catalogo.sistema.descricao}
                      <button
                        type="button"
                        onClick={() =>
                          handleFiltro('FLT_IDS', catalogo.sistema.codiSist)
                        }
                      >
                        <MdClose size={12} />
                      </button>
                    </span>
                  )}

                  {catalogo.local && (
                    <span>
                      {catalogo.local.descricao}
                      <button
                        type="button"
                        onClick={() =>
                          handleFiltro('FLT_IDL', catalogo.local.codiLoca)
                        }
                      >
                        <MdClose size={12} />
                      </button>
                    </span>
                  )}
                </Menu.Content.Filter>
                <TreeView data={catalogo} />
              </Menu.Content.TreeView>
            </Modal>
          )}

          <Main>
            <Main.Container>
              <Main.Wrapper>
                <Content>
                  <Content.BtnWrapper>
                    <ContentToolBarHeaderButton
                      onClick={() => handleShowBreadcrumbs(showBreadcrumbs)}
                    >
                      {!showBreadcrumbs ? (
                        <MdKeyboardArrowDown size={16} />
                      ) : (
                        <MdKeyboardArrowUp size={16} />
                      )}
                    </ContentToolBarHeaderButton>
                    <BtnTreeView onClick={() => handleTreeView()}>
                      <MdListAlt size={25} />
                    </BtnTreeView>
                  </Content.BtnWrapper>
                  <Content.ToolBar>
                    {catalogo && showBreadcrumbs && handleBreadcrumb(catalogo)}

                    {controle && showBreadcrumbs && (
                      <Content.ToolBar.DrawControl>
                        <button
                          type="button"
                          onClick={debounce(() => handlePageNav(-1), 100)}
                        >
                          <MdChevronLeft size={20} />
                        </button>
                        <span>{shownPage}</span>
                        <button
                          type="button"
                          onClick={debounce(() => handlePageNav(1), 100)}
                        >
                          <MdChevronRight size={20} />
                        </button>
                      </Content.ToolBar.DrawControl>
                    )}
                  </Content.ToolBar>

                  {catalogo && handleContainer(catalogo)}
                </Content>
              </Main.Wrapper>
            </Main.Container>
          </Main>
        </>
      )}
    </Container>
  );
}
