import React, { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import logotipo from '../../../../assets/logo.svg'
import MarcaDAgua from '../../../../assets/marca-dagua-assiste.svg';

import { signInRequest } from '../../../../store/modules/auth/actions';

import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import { Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';

import { Container, Wrapper, Footer } from './styles';

const schema = Yup.object().shape({
  login: Yup.string().required('O campo "login" é obrigatório!'),
  password: Yup.string().required('O campo "senha" é obrigatório!'),
});

function MobileUsuarioLogin() {
  const formRef = useRef(null);
  const loading = useSelector((state) => state.loading);
  const dispatch = useDispatch();

  const location = useLocation();

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        /** pega parâmetros da rota */
        const paramQuery = new URLSearchParams(location.search);
        const { return_to: returnTo = '' } = Object.fromEntries(paramQuery);

        const { login, password } = data;

        dispatch(signInRequest(login, password, returnTo));
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [dispatch, location]
  );

  // const handleSignin = useCallback(() => {
  //   // history.push(`/signin${location.search}`);
  // }, []);

  const handleRecuperaAcesso = useCallback(() => {
    history.push('/recupera-acesso')
  }, [])

  return (
    <Container>
      <Container.Logo>
        <img src={logotipo} alt="Assiste" />
      </Container.Logo>
      <Wrapper>
        <Form
          schema={schema}
          ref={formRef}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <h1>Bem-Vindo</h1>
          <Input
            id="login"
            name="login"
            // label="Login"
            type="text"
            placeholder="Login"
          />

          <Input
            id="password"
            name="password"
            // label="Senha"
            type="password"
            placeholder="Senha"
          />
          <button id="btn-forgot" type="button" onClick={handleRecuperaAcesso} >
            Esqueceu a senha?
          </button>

          <button id="btn-submit" type="submit">
            {loading ? 'Carregando...' : 'Entrar'}
          </button>

          {/* <span>
            Não tem uma conta?
            <button id="btn-signup" type="button" onClick={handleSignin}>
              Registre-se
            </button>
          </span> */}
        </Form>
      </Wrapper>

      <Footer>
        <a
          href="http://assiste.com.br/"
          target="_blank"
          rel="noopener noreferrer"
          title={`Assiste - CDI - v${process.env.REACT_APP_VERSION}`}
        >
          <img src={MarcaDAgua} alt="logotipo" />
        </a>
      </Footer>
    </Container>
  );
}

export { MobileUsuarioLogin };
