import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useSpring } from 'react-spring';
import {
  MdClose,
  MdShoppingCart,
  MdLens,
  MdLabelOutline,
} from 'react-icons/md';
import { SlOptions } from 'react-icons/sl';

import { PDF, opPdfExport } from '../../../../../lib/pdf-core';

import { initialParams } from '../../../../../lib/reactRouter';
import { useScrollLock } from '../../../../../lib/pageUtils';
import { redirectToCart } from '../../../../../lib/paramUtils';
import { adicionaCarrinhoRequest } from '../../../../../store/modules/cart/actions';

import history from '../../../../../services/history';
import api from '../../../../../services/api';

import { MobileItemCart } from '../../../../../components/Mobile/ItemCart';

import Modal from '../../../../../components/Modal';

import { emailTipo } from '../../../../Usuario/_usuario-email';

import {
  Container,
  Wrapper,
  ZoomArea,
  ItemArea,
  ItemAreaExtend,
  Table,
  Info,
  SVG,
  NoteContainer,
  NoteContent,
  Notes,
  NotesModal,
  NoteHeader,
  BtnFlutuante,
  ListaOpcoes,
  Opcoes,
} from './styles';

const MobilePagina = forwardRef(
  ({ id, idPagina, zoomInRef, zoomOutRef, zoomResetRef }, ref) => {
    const carrinho = useSelector((state) =>
      state.cart.items.map((item) => ({
        ...item,
        ...item.materialFabricante,
      }))
    );

    const containerRef = useRef();
    const mappingRef = useRef([]);
    const svgRef = useRef();

    const location = useLocation();

    const [pagina, setPagina] = useState(null);
    const [itensPagina, setItensPagina] = useState([]);

    const [itemNote, setItemNote] = useState([]);

    const [showNote, setShowNote] = useState(false);

    const [itemInfo, setItemInfo] = useState(null);
    const [itemCart, setItemCart] = useState(null);

    const [paginaMaximizada, setPaginaMaximizada] = useState(false);
    const [tabelaMaximizada, setTabelaMaximizada] = useState(false);

    const [, setZoomStep] = useState(5);

    const [elementToZoom, setElementToZoom] = useState();

    const [opcoes, setOpcoes] = useState(false);

    const dispatch = useDispatch();

    const { lockScroll, unlockScroll } = useScrollLock();

    useEffect(() => {
      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);

      const paramRoute = {
        ...initialParams,
        ...Object.fromEntries(paramQuery),
      };

      api.get(`catalogo-publico/${id}/pagina/${idPagina}`).then((response) => {
        const { nota } = response.data;

        setPagina(response.data);
        setItemNote(() => (nota ? nota.split(';').filter(Boolean) : []));
      });

      api
        .get(`/catalogo-publico/${id}/pagina/${idPagina}/item`, {
          params: {
            ...paramRoute,
          },
        })
        .then((response) => {
          setItensPagina(
            response.data.responseItemPagina.map((item) => {
              const { idItem, materialEmpresa, materialFabricante } = item;

              return {
                ...item,
                materialEmpresa,
                ...materialFabricante,
                itemResum:
                  String(idItem).startsWith('@') || String(idItem) === '0'
                    ? ''
                    : idItem,
                // Concatena os materiais da empresa
                codimateResum:
                  materialEmpresa &&
                  materialEmpresa
                    .map((material) => material.codimate)
                    .join(', '),
                selecionado: false,
                exibido: false,
              };
            })
            // .sort((a, b) => a.ordena - b.ordena)
          );
        });

      window.addEventListener('resize', () => {
        /** reposiciona o desenho conforme a página é redimensionada */
        if (zoomResetRef.current && zoomResetRef.current.onclick) {
          zoomResetRef.current.onclick();
        }
      });

      // return window.removeEventListener('resize', handleSVGResize);
    }, [location, id, idPagina, zoomResetRef]);

    const handleImprimirPagina = useCallback(
      async (isSendMail = false) => {
        /** cria PDF com a página do catálogo */
        PDF.criarExportacao(
          opPdfExport.CATALOGO_PAGINA,
          {
            idCatalogo: id,
            idPagina,
          },
          (filename) => {
            if (isSendMail) {
              /** carrega foto para anexar no formulário de envio de e-mail */
              api.get(filename).then((response) => {
                const blobFile = new Blob([response.data], {
                  type: 'application/pdf',
                });

                history.push('/enviar-email', {
                  background: location,
                  tipo: emailTipo.EMAIL_PAGINA,
                  data: { attachment: blobFile },
                });
              });
            } else {
              const link = document.createElement('a');

              link.setAttribute('href', filename);

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        );
      },
      [location, id, idPagina]
    );

    const handleRedimensionarPagina = useCallback(
      (maximizado) => {
        setTimeout(() => {
          if (maximizado) {
            const { width } = containerRef.current.getBoundingClientRect();

            setZoomStep(Math.trunc(width * 0.02));
          } else {
            setZoomStep(5);
          }

          if (zoomResetRef.current) {
            zoomResetRef.current.onclick();
          }
        }, 100);

        setPaginaMaximizada(maximizado);
      },
      [zoomResetRef]
    );

    useImperativeHandle(ref, () => ({
      handleImprimirPagina,
      handleRedimensionarPagina,
    }));

    const handleItemMouseEnter = useCallback((idItem) => {
      mappingRef.current.map((relacionamento) => {
        if (relacionamento.idItem === idItem) {
          relacionamento.node.classList.add('selecting');
        }
        return relacionamento;
      });
    }, []);

    const handleItemMouseLeave = useCallback((idItem) => {
      mappingRef.current.map((relacionamento) => {
        if (relacionamento.idItem === idItem) {
          if (!relacionamento.selected && !relacionamento.inCart) {
            relacionamento.node.classList.remove('selecting');
          }
        }
        return relacionamento;
      });
    }, []);

    const handleRelacionar = useCallback(() => {
      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);

      const paramRoute = {
        ...Object.fromEntries(paramQuery),
      };

      const { IDI: itemSearch } = paramRoute || {};

      /** SELECTING: ITEM QUE ESTAO NO CARRINHO */
      mappingRef.current.forEach((mapeamento) => {
        if (
          carrinho.find(
            (itemCarrinho) =>
              Number(itemCarrinho.idCatalogo) === id &&
              Number(itemCarrinho.idPagina) === idPagina &&
              itemCarrinho.idItem === mapeamento.idItem
          )
        ) {
          mapeamento.node.classList.add('selecting');
          mapeamento.inCart = true;
        }
      });

      /** SELECTING: ITEM PESQUISADO */
      let itemPaginaExt = null;
      if (itemSearch) {
        const [
          ,
          idPaginaSearch,
          idItemSearch,
          idMaterialSearch,
        ] = itemSearch.split('.');

        if (idItemSearch && idMaterialSearch) {
          itemPaginaExt = itensPagina.find(
            (i) =>
              String(idItemSearch) === String(i.idItem) &&
              Number(idPaginaSearch) === Number(i.idPagina)
          );

          if (itemPaginaExt) {
            const mapping = mappingRef.current.find(
              (item) => String(item.idItem) === String(itemPaginaExt.idItem)
            );
            if (mapping) mapping.node.onclick();
          }
        }
      }
      setItemCart(itemPaginaExt);
    }, [location, id, idPagina, carrinho, itensPagina]);

    const handleItemFechar = useCallback(
      (data) => {
        const { idItem } = itemCart;

        const itemIndex = itensPagina.findIndex(
          (item) => item.idItem === idItem
        );

        if (itemIndex >= 0) {
          itensPagina[itemIndex].exibido = false;
          itensPagina[itemIndex].selecionado = false;

          const relacionamentos = mappingRef.current.filter(
            (item) => item.idItem === idItem
          );
          relacionamentos.forEach((relacionamento) => {
            relacionamento.selected = false;
            if (data) {
              relacionamento.inCart = true;
            }

            if (!relacionamento.inCart) {
              relacionamento.node.classList.remove('selecting');
            }
          });
        }

        setItemCart(null);
      },
      [itemCart, itensPagina]
    );

    const handleAdicionar = useCallback(
      (itemAdicionar) => {
        const {
          idItem,
          idMaterial,
          quantidade,
          materialEmpresa,
        } = itemAdicionar;

        /** limpa seleções anteriores que não foram adicionadas no carrinho */
        mappingRef.current.forEach((relacionamento) => {
          if (!relacionamento.inCart) {
            relacionamento.selected = false;
            relacionamento.node.classList.remove('selecting');
          }
        });

        const relacionamentos = mappingRef.current.filter(
          (item) => item.idItem === idItem
        );

        relacionamentos.forEach((relacionamento) => {
          relacionamento.node.classList.add('selecting');
          relacionamento.selected = true;
        });

        /** verifica quantidade de material ERP e parâmetro para adicionar automaticamente para o carrinho */
        if (materialEmpresa.length === 1 && redirectToCart()) {
          /** caso existe material relacionado, pega o código */
          const { codimate } = materialEmpresa[0];

          dispatch(
            adicionaCarrinhoRequest(
              id,
              idPagina,
              idItem,
              idMaterial,
              quantidade,
              codimate
            )
          );

          relacionamentos.forEach((relacionamento) => {
            relacionamento.inCart = true;
          });

          return;
        }

        /** caso tenha mais de um material relacionado, abre o modal */
        setItemCart(itemAdicionar);
      },
      [id, idPagina, dispatch]
    );

    const handleLinkClick = useCallback(
      (e, idItem) => {
        console.log(e);
        // e.preventDefault();
        if (e) e.preventDefault();

        // const [, , , idItem] = e.target.parentElement.href.baseVal.split('/');

        const itemIndex = itensPagina.findIndex(
          (item) => item.idItem === idItem
        );

        if (itemIndex >= 0) {
          itensPagina[itemIndex].exibido = true;

          // const relacionamentos = mappingRef.current.filter(
          //   (item) => item.idItem === idItem
          // );
          // relacionamentos.forEach((relacionamento) => {
          //   relacionamento.node.classList.add('selecting');
          //   relacionamento.selected = true;
          // });

          /** oculta informativo e exibe o modal */
          setItemInfo(null);

          handleAdicionar(itensPagina[itemIndex]);

          // setItemCart(itensPagina[itemIndex]);
        }
      },
      [itensPagina, handleAdicionar]
    );

    const handleLinkMouseEnter = useCallback(
      (e, idItem) => {
        console.log(e);
        e.preventDefault();

        // const [, , , idItem] = e.target.href.baseVal.split('/');

        const itemIndex = itensPagina.findIndex(
          (item) => item.idItem === idItem
        );
        if (itemIndex >= 0) {
          itensPagina[itemIndex].selecionado = true;

          const relacionamentos = mappingRef.current.filter(
            (item) => item.idItem === idItem
          );
          relacionamentos.forEach((relacionamento) => {
            relacionamento.node.classList.add('selecting');
          });

          const {
            left: leftContainer,
            top: topContainer,
          } = containerRef.current.getBoundingClientRect();

          const a = e.target.getBoundingClientRect();

          setItemInfo({
            ...itensPagina[itemIndex],
            posX: a.left - leftContainer - 90,
            posY: a.top - topContainer + a.height + 5,
          });
        }
      },
      [itensPagina]
    );

    const handleLinkMouseOut = useCallback(
      (e, idItem) => {
        console.log(e);
        e.preventDefault();

        const itemIndex = itensPagina.findIndex(
          (item) => item.idItem === idItem
        );
        if (itemIndex >= 0) {
          if (!itensPagina[itemIndex].exibido) {
            itensPagina[itemIndex].selecionado = false;
          }

          const relacionamentos = mappingRef.current.filter(
            (item) => item.idItem === idItem
          );

          relacionamentos.forEach((relacionamento) => {
            if (!relacionamento.selected && !relacionamento.inCart) {
              relacionamento.node.classList.remove('selecting');
            }
          });

          /** oculta informativo */
          setItemInfo(null);
        }
      },
      [itensPagina]
    );

    const handleLink = useCallback(
      (childNodes) => {
        childNodes.forEach((node) => {
          if (node.nodeName === 'a') {
            const [, , , idItem] = node.href.baseVal.split('/');

            mappingRef.current.push({
              idItem,
              node,
              inCart: false,
              selected: false,
            });

            // node.addEventListener('click', (e) => handleLinkClick(e, idItem));
            node.onclick = (e) => handleLinkClick(e, idItem);

            node.addEventListener('mouseenter', (e) =>
              handleLinkMouseEnter(e, idItem)
            );

            node.addEventListener('mouseout', (e) => {
              /** previne a saída quando o mouse está sobre o texto */
              if (e.relatedTarget.parentElement !== e.target.parentElement) {
                handleLinkMouseOut(e, idItem);
              }
            });
          }

          /** processa nós do svg */
          if (node.hasChildNodes()) {
            return handleLink(node.childNodes);
          }

          return true;
        });
      },
      [handleLinkClick, handleLinkMouseEnter, handleLinkMouseOut]
    );

    const editorSvg = useMemo(
      () => (
        <SVG
          src={pagina && pagina.url}
          loading={() => <span>Loading</span>}
          useRequestCache={false}
          afterInjection={(err, svg) => {
            if (svg) {
              mappingRef.current = [];
              handleLink(svg.childNodes);
              handleRelacionar();
              // svg.setAttribute('width', '420');
              // svg.setAttribute('height', '390');

              // svg.setAttribute('viewBox', '0 0 750 850');
              svg.removeAttribute('width');
              svg.removeAttribute('height');

              svgRef.current = svg;

              setTimeout(() => {
                if (zoomResetRef.current) {
                  zoomResetRef.current.onclick();
                }
              }, 100);
            }
          }}
        />
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pagina, handleLink, zoomResetRef]
    );

    const [noteStyle, spring] = useSpring(() => ({
      config: { mass: 2, tension: 80, friction: 25, precision: 0.001 },
    }));

    useEffect(() => {
      if (showNote) {
        lockScroll();
      } else {
        unlockScroll();
      }

      spring.start({
        opacity: showNote ? 1 : 0,
        scale: showNote ? 1 : 0,
      });

      return () => unlockScroll();
    }, [showNote, spring, lockScroll, unlockScroll]);

    useEffect(() => {
      if (itemCart) {
        window.onclick = (e) => {
          if (
            e.target.tagName === 'svg:circle' ||
            e.target.tagName === 'svg:square'
          ) {
            setElementToZoom(e);
          }
          setElementToZoom(null);
        };
        setElementToZoom(null);
      }
    }, [itemCart]);

    const renderizaNivel = (item) => {
      const niveis = [];
      for (let i = 0; i < item.nivelConjuntoItemPagina; i += 1) {
        niveis.push(
          <span key={i}>
            <MdLens id="lens" />
          </span>
        );
      }
      return niveis;
    };

    const handleOpcoes = () => {
      setOpcoes(!opcoes);
    };

    const handleTabelaMaximizada = () => {
      setTabelaMaximizada(!tabelaMaximizada);
    };

    return (
      <Container>
        {pagina && !tabelaMaximizada && (
          <Wrapper>
            <ZoomArea ref={containerRef}>
              <TransformWrapper
                initialScale={3}
                // initialPositionX={-300}
                // initialPositionY={-100}
                centerOnInit
                centerZoomedOut
                doubleClick={{
                  step: 0.7,
                  animationTime: 500,
                  animationType: 'easeInOutQuad',
                }}
                // wheel={{ step: 0.2 }}
              >
                {({ zoomIn, zoomOut, resetTransform, setTransform }) => {
                  if (
                    zoomInRef &&
                    zoomInRef.current &&
                    !zoomInRef.current.onclick
                  ) {
                    zoomInRef.current.onclick = () =>
                      zoomIn(0.2, 500, 'easeOut');
                  }

                  if (
                    zoomOutRef &&
                    zoomOutRef.current &&
                    !zoomOutRef.current.onclick
                  ) {
                    zoomOutRef.current.onclick = () =>
                      zoomOut(0.2, 500, 'easeOut');
                  }

                  if (
                    zoomResetRef &&
                    zoomResetRef.current &&
                    !zoomResetRef.current.onclick
                  ) {
                    zoomResetRef.current.onclick = () =>
                      resetTransform(500, 'easeOut');
                  }

                  if (itemCart && elementToZoom) {
                    setTransform(
                      -Math.abs(elementToZoom.layerX),
                      -Math.abs(elementToZoom.layerY),
                      2,
                      1000,
                      'easeInOutQuad'
                    );
                  }

                  return <TransformComponent>{editorSvg}</TransformComponent>;
                }}
              </TransformWrapper>

              {itemNote.length > 0 && (
                <NoteContainer
                  onMouseDown={() => setShowNote((state) => !state)}
                >
                  <NoteContent>
                    <Notes>
                      <p>
                        {itemNote[0]}
                        {itemNote[1] && '...'}
                      </p>
                    </Notes>
                  </NoteContent>
                </NoteContainer>
              )}

              <NotesModal
                style={{
                  opacity: noteStyle.opacity,
                  scale: noteStyle.scale,
                }}
              >
                <NoteHeader>
                  <strong>Notas da Página</strong>
                  <button
                    type="button"
                    onClick={() => setShowNote((state) => !state)}
                  >
                    <MdClose size={25} />
                  </button>
                </NoteHeader>

                <ul>
                  {!!itemNote.length &&
                    itemNote.map((item, index) => <p key={index}>{item}</p>)}
                </ul>
              </NotesModal>

              {itemInfo && (
                <Info posX={itemInfo.posX} posY={itemInfo.posY}>
                  <strong>{itemInfo.descricao}</strong>
                  <p>{itemInfo.partnumber}</p>
                </Info>
              )}

              <BtnFlutuante onClick={() => handleOpcoes()}>
                <SlOptions />
              </BtnFlutuante>
              {opcoes && (
                <Modal
                  width={50}
                  height={paginaMaximizada ? 30 : 50}
                  onClose={handleOpcoes}
                >
                  <ul>
                    {!paginaMaximizada && (
                      <ListaOpcoes>
                        <Opcoes
                          onClick={() => {
                            handleRedimensionarPagina(!paginaMaximizada);
                            handleOpcoes();
                          }}
                        >
                          Imagem
                        </Opcoes>
                      </ListaOpcoes>
                    )}
                    <ListaOpcoes>
                      <Opcoes
                        onClick={() => {
                          setPaginaMaximizada(false);
                          handleTabelaMaximizada();
                          handleOpcoes();
                        }}
                      >
                        Itens
                      </Opcoes>
                    </ListaOpcoes>
                    {(tabelaMaximizada || paginaMaximizada) && (
                      <ListaOpcoes>
                        <Opcoes
                          onClick={() => {
                            handleRedimensionarPagina(false);
                            handleOpcoes();
                          }}
                        >
                          Ambos
                        </Opcoes>
                      </ListaOpcoes>
                    )}
                  </ul>
                </Modal>
              )}
            </ZoomArea>
          </Wrapper>
        )}

        {/* 
            => ADICIONAR O MODAL ENVOLVENDO O ItemCartMobile
            => E RETIRAR O WRAPPER CRAIADO NO MESMO
        */}

        {itemCart && (
          <MobileItemCart item={itemCart} onClose={handleItemFechar} />
        )}

        {!paginaMaximizada && !tabelaMaximizada && (
          <ItemArea>
            {itensPagina && (
              <Table>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Descrição</th>
                    {/* <th>Partnumber</th>
                    <th>Quantidade</th> */}
                    <th>Adicionar</th>
                  </tr>
                </thead>
                <tbody>
                  {itensPagina.map((item, index) => (
                    <Table.Row
                      onMouseEnter={() => handleItemMouseEnter(item.idItem)}
                      onMouseLeave={() => handleItemMouseLeave(item.idItem)}
                      selected={item.selecionado}
                      key={index}
                    >
                      <td>
                        <div>
                          {item.isConjunto && (
                            <span>
                              <MdLabelOutline />
                            </span>
                          )}
                          {renderizaNivel(item)}
                          {item.itemResum}
                        </div>
                      </td>
                      <td>{item.descricao}</td>
                      {/* <td>{item.partnumber}</td>
                      <td>{item.quantidade}</td> */}
                      <td>
                        <Table.BtnCart
                          inCart={
                            !!carrinho.find(
                              (itemCarrinho) =>
                                Number(itemCarrinho.idCatalogo) ===
                                  Number(item.idCatalogo) &&
                                Number(itemCarrinho.idPagina) ===
                                  Number(item.idPagina) &&
                                String(itemCarrinho.idItem) ===
                                  String(item.idItem) &&
                                Number(itemCarrinho.idMaterial) ===
                                  Number(item.idMaterial)
                            )
                          }
                          type="button"
                          onClick={() => handleAdicionar(item)}
                        >
                          <MdShoppingCart size={20} />
                        </Table.BtnCart>
                      </td>
                    </Table.Row>
                  ))}
                </tbody>
              </Table>
            )}
          </ItemArea>
        )}

        {tabelaMaximizada && (
          <>
            <ItemAreaExtend>
              {itensPagina && (
                <Table>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Descrição</th>
                      {/* <th>Partnumber</th>
                      <th>Quantidade</th> */}
                      <th>Adicionar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itensPagina.map((item, index) => (
                      <Table.Row
                        onMouseEnter={() => handleItemMouseEnter(item.idItem)}
                        onMouseLeave={() => handleItemMouseLeave(item.idItem)}
                        selected={item.selecionado}
                        key={index}
                      >
                        <td>
                          <div>
                            {item.isConjunto && (
                              <span>
                                <MdLabelOutline />
                              </span>
                            )}
                            {renderizaNivel(item)}
                            {item.itemResum}
                          </div>
                        </td>
                        <td>{item.descricao}</td>
                        {/* <td>{item.partnumber}</td>
                        <td>{item.quantidade}</td> */}
                        <td>
                          <Table.BtnCart
                            inCart={
                              !!carrinho.find(
                                (itemCarrinho) =>
                                  Number(itemCarrinho.idCatalogo) ===
                                    Number(item.idCatalogo) &&
                                  Number(itemCarrinho.idPagina) ===
                                    Number(item.idPagina) &&
                                  String(itemCarrinho.idItem) ===
                                    String(item.idItem) &&
                                  Number(itemCarrinho.idMaterial) ===
                                    Number(item.idMaterial)
                              )
                            }
                            type="button"
                            onClick={() => handleAdicionar(item)}
                          >
                            <MdShoppingCart size={20} />
                          </Table.BtnCart>
                        </td>
                      </Table.Row>
                    ))}
                  </tbody>
                  <BtnFlutuante id="flutuante" onClick={() => handleOpcoes()}>
                    <SlOptions />
                  </BtnFlutuante>
                  {opcoes && (
                    <Modal width={50} height={20} onClose={handleOpcoes}>
                      <ul>
                        {!paginaMaximizada && (
                          <ListaOpcoes>
                            <Opcoes
                              onClick={() => {
                                setTabelaMaximizada(false);
                                handleRedimensionarPagina(!paginaMaximizada);
                                handleOpcoes();
                              }}
                            >
                              Imagem
                            </Opcoes>
                          </ListaOpcoes>
                        )}
                        {!tabelaMaximizada && (
                          <ListaOpcoes>
                            <Opcoes
                              onClick={() => {
                                handleTabelaMaximizada();
                                handleOpcoes();
                              }}
                            >
                              Itens
                            </Opcoes>
                          </ListaOpcoes>
                        )}
                        {(tabelaMaximizada || paginaMaximizada) && (
                          <ListaOpcoes>
                            <Opcoes
                              onClick={() => {
                                setTabelaMaximizada(false);
                                handleRedimensionarPagina(false);
                                handleOpcoes();
                              }}
                            >
                              Ambos
                            </Opcoes>
                          </ListaOpcoes>
                        )}
                      </ul>
                    </Modal>
                  )}
                </Table>
              )}
            </ItemAreaExtend>
          </>
        )}
      </Container>
    );
  }
);

MobilePagina.propTypes = {
  id: PropTypes.number.isRequired,
  idPagina: PropTypes.number.isRequired,
  zoomInRef: PropTypes.instanceOf(Object).isRequired,
  zoomOutRef: PropTypes.instanceOf(Object).isRequired,
  zoomResetRef: PropTypes.instanceOf(Object).isRequired,
};

export { MobilePagina };
