import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { MdList } from 'react-icons/md';
import { HiClipboardDocumentList } from "react-icons/hi2";

import { allowEmpCatalogConversor } from '../../../../lib/paramUtils';
import { conCatalogoSituacao, opTipoAcesso } from '../../../../lib/const';
import { opCatalogoSituacao, opIdioma } from '../../../../lib/inputOption';

import api from '../../../../services/api';
import history from '../../../../services/history';

import TitleBar from '../../../../components/TitleBar';

import Pagination from '../../../../components/EditorAI/Pagination';
import { Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import InputViewer from '../../../../components/Form/Input/Viewer';

import TableHeader from '../../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../../components/Table';

import ViewerDetail from '../../../../components/ViewerDetail';

import { Container, Wrapper, CatalogStatus } from './styles';

function EmpresaCatalogoDetalhe() {
  const { adm: tipoAcesso } = useSelector((state) => state.user.profile || {});

  const location = useLocation();
  const { idEmpresa, id } = location.state;

  const formRef = useRef(null);

  const [empresa, setEmpresa] = useState(null);
  const [empresaCatalogo, setEmpresaCatalogo] = useState(null);
  const [relacEmprCatalogo, setRelacEmprCatalogo] = useState([]);

  const [viewerDetail, setViewerDetail] = useState(null);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  /** pesquisa */
  const [pesquisa, setPesquisa] = useState(null);

  useEffect(() => {
    api.get(`empresa/${idEmpresa}/catalogo/${id}`).then((response) => {
      const {
        idioma,
        instAlt,
        instPubl,
        empresas,
        arquivo,
        empresaResponsavel,
        series,
        ...catalogo
      } = response.data.catalogo;

      const { modelos, fabricante } = arquivo;

      /** formata campo modelo */
      const fmtModelo = modelos.map((modelo) => modelo.descricao).join(', ');

      /** formata campo número de série */
      const fmtNumeroSerie = series
        .map((serie) => serie.numeroSerie)
        .join(', ');

      const qtdeModelo = modelos.length || 0;
      const qtdeNumeroSerie = series.length || 0;

      setEmpresa(response.data.empresa);
      setEmpresaCatalogo({
        ...catalogo,
        idioma: opIdioma[idioma].label,
        instAlt: instAlt && format(parseISO(instAlt), 'dd/MM/yyyy HH:mm:ss'),
        instPubl: instPubl && format(parseISO(instPubl), 'dd/MM/yyyy HH:mm:ss'),
        fabricante: fabricante.descricao,
        modelos,
        qtdeModelo,
        modelo: fmtModelo,
        series,
        numeroSerie: fmtNumeroSerie,
        qtdeNumeroSerie,
        relacionadoEmpresa:
          empresas.filter((e) => e.idEmpresa === idEmpresa).length > 0,
        // Permite alterar os catálogos da própria empresa
        allowModification: idEmpresa === empresaResponsavel.idEmpresa,
        // && catalogo.situacao === conCatalogoSituacao.EM_DESENVOLVIMENTO,
      });
    });
  }, [idEmpresa, id]);

  useEffect(() => {
    /** verifica campos de pesquisa */
    const query = pesquisa || null;
    api
      .get(`empresa/${idEmpresa}/catalogo/${id}/relacionamento-sistema-local`, {
        params: {
          page: currentPage,
          query,
        },
      })
      .then((response) => {
        const relac = response.data.paginaCatalogo.reduce(
          (accPagina, currPagina) => {
            const { idPagina, descricao, relacionamento = [] } =
              currPagina || {};

            relacionamento.forEach((currRelacionamento) => {
              const { relacLocal, relacSistema } = currRelacionamento;

              accPagina.push({
                paginaCatalogo: {
                  idPagina,
                  descricao,
                },
                local: relacLocal,
                sistema: relacSistema,
              });
            });

            return accPagina;
          },
          []
        );

        setRelacEmprCatalogo(relac);
        /** ajusta dados da página */
        setTotalPage(relac.length);
        if (currentPage > relac.length) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [idEmpresa, id, currentPage, pesquisa]);

  const handleEstruturar = useCallback(() => {
    history.push('/catalogo/detalhe', { id });
  }, [id]);

  const handleTokenAcesso = useCallback(() => {
    history.push(`/empresa/catalogo/token-acesso`, {
      idEmpresa,
      idCatalogo: id,
    });
  }, [idEmpresa, id]);

  const handleCatalogoNavegacao = useCallback(() => {
    history.push(`/empresa/catalogo/catalogo-navegacao/criar`, {
      background: location,
      idEmpresa,
      idCatalogo: id,
    });
  }, [idEmpresa, id, location]);

  const handleGerenciar = useCallback(() => {
    history.push(`/empresa/catalogo/pagina-sistema-local`, {
      idEmpresa,
      idCatalogo: id,
    });
  }, [idEmpresa, id]);

  const handleMaterial = useCallback(() => {
    history.push(`/empresa/catalogo/material`, {
      idEmpresa,
      idCatalogo: id,
    });
  }, [idEmpresa, id]);

  const handleConferenciaCatalogo = useCallback(() => {
    history.push(`/catalogo/merge`, {
      idCatalogo: id,
    });
  }, [id])

  return (
    <Container>
      <TitleBar back />

      <h2>Detalhes</h2>
      {empresaCatalogo && (
        <Wrapper>
          <Form initialData={empresaCatalogo}>
            <Form.Row>
              {Number(tipoAcesso) === opTipoAcesso.ADMIN_PLATAFORMA && (
                <InputViewer
                  id="idCatalogo"
                  name="idCatalogo"
                  label="Catálogo"
                />
              )}
              <InputViewer id="nome" name="nome" label="Classe Operacional" />
              <CatalogStatus type={empresaCatalogo.situacao}>
                {opCatalogoSituacao[empresaCatalogo.situacao]
                  ? opCatalogoSituacao[empresaCatalogo.situacao].label
                  : ''}
              </CatalogStatus>
            </Form.Row>
            <Form.Row>
              <InputViewer id="idioma" name="idioma" label="Idioma" />
              <InputViewer id="versao" name="versao" label="Versão" />
              <InputViewer
                id="numeroSerie"
                name="numeroSerie"
                label="Número Série"
                type="attach"
                attachLabel={
                  empresaCatalogo.qtdeNumeroSerie > 0
                    ? `(${empresaCatalogo.qtdeNumeroSerie})`
                    : ''
                }
                fnAttach={() =>
                  setViewerDetail({
                    title: 'Lista de números de série relacionados',
                    data: empresaCatalogo.series.map(
                      (numeroSerie) =>
                        `${numeroSerie.idSerie} - ${numeroSerie.numeroSerie} (${numeroSerie.partnumber || '-'
                        })`
                    ),
                  })
                }
              />
            </Form.Row>
            <Form.Row>
              <InputViewer
                id="fabricante"
                name="fabricante"
                label="Fabricante"
              />
              <InputViewer
                id="modelo"
                name="modelo"
                label="Modelo"
                type="attach"
                attachLabel={
                  empresaCatalogo.qtdeModelo > 0
                    ? `(${empresaCatalogo.qtdeModelo})`
                    : ''
                }
                fnAttach={() =>
                  setViewerDetail({
                    title: 'Lista de modelos relacionados',
                    data: empresaCatalogo.modelos.map(
                      (modelo) => `${modelo.idModelo} - ${modelo.descricao}`
                    ),
                  })
                }
              />
              <InputViewer
                id="instAlt"
                name="instAlt"
                label="Última atualização"
              />
            </Form.Row>
            <Form.Separate height={40} />
            <Form.Footer withStart>
              {allowEmpCatalogConversor() && empresaCatalogo.allowModification && (
                <Form.Footer.Link type="button" onClick={handleEstruturar}>
                  <MdList size={18} />
                  Estrutura
                </Form.Footer.Link>
              )}
              {empresaCatalogo.situacao === conCatalogoSituacao.PUBLICADO && (
                <>
                  {empresaCatalogo.relacionadoEmpresa && (
                    <Form.Footer.Link type="button" onClick={handleTokenAcesso}>
                      <MdList size={18} />
                      Token de acesso
                    </Form.Footer.Link>
                  )}
                  <Form.Footer.Link type="button" onClick={handleGerenciar}>
                    <MdList size={18} />
                    Relac. Sistema / Local
                  </Form.Footer.Link>
                  <Form.Footer.Link type="button" onClick={handleMaterial}>
                    <MdList size={18} />
                    Material ERP
                  </Form.Footer.Link>
                </>
              )}
              {empresa && empresa.navegacaoPersonalizada && (
                <Form.Footer.Link
                  type="button"
                  onClick={handleCatalogoNavegacao}
                >
                  <MdList size={18} />
                  Relac. Menu Principal
                </Form.Footer.Link>
              )}

              {tipoAcesso === opTipoAcesso.ADMIN_PLATAFORMA && (
                <Form.Footer.Link
                  type="button"
                  onClick={handleConferenciaCatalogo}
                >
                  <HiClipboardDocumentList size={18} />
                  Conferência
                </Form.Footer.Link>
              )}

            </Form.Footer>
          </Form>
        </Wrapper>
      )}

      <h2>Páginas relacionadas</h2>
      <Form
        ref={formRef}
        onSubmit={(data) => setPesquisa(data.search)}
        autoComplete="off"
      >
        <Input
          id="search"
          name="search"
          type="text"
          placeholder="Digite para pesquisar..."
          width={300}
          onChange={() => formRef.current.submitForm()}
        />
      </Form>

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'idPagina', title: 'Página' },
            { fieldname: 'codiSist', title: 'Sistema' },
            { fieldname: 'codiLoca', title: 'Local' },
          ]}
          cbSort={() => { }}
        />
        <tbody>
          {relacEmprCatalogo.map((relacionamento, index) => (
            <RowMaster key={index}>
              <td>{`${relacionamento.paginaCatalogo.idPagina}: ${relacionamento.paginaCatalogo.descricao}`}</td>
              <td>{`${relacionamento.sistema.codiSist}: ${relacionamento.sistema.descricao}`}</td>
              <td>{`${relacionamento.local.codiLoca}: ${relacionamento.local.descricao}`}</td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />

      {viewerDetail && (
        <ViewerDetail handleClose={() => setViewerDetail(null)}>
          <h2>{viewerDetail.title}</h2>
          <ul>
            {viewerDetail.data.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </ViewerDetail>
      )}
    </Container>
  );
}

export { EmpresaCatalogoDetalhe };
