import React from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import Route from './Route';

import { opLayout } from '../lib/const';

import { Importacao } from '../components/Form/Importacao';
import { FiltroPesquisa } from '../pages/FiltroPesquisa';

import { Usuario } from '../pages/Usuario';
import { UsuarioCriar } from '../pages/Usuario/criar';
import { UsuarioEditar } from '../pages/Usuario/editar';
import { UsuarioDetalhe } from '../pages/Usuario/detalhe';
import { UsuarioPerfil } from '../pages/Usuario/_usuario-perfil';
import { UsuarioTrocarSenha } from '../pages/Usuario/_usuario-troca-senha';
import { UsuarioEmail } from '../pages/Usuario/_usuario-email';
import { UsuarioWhatsApp } from '../pages/Usuario/_usuario-whatsapp';

import { UsuarioLogin } from '../pages/Usuario/_usuario-login';
import { UsuarioSignin } from '../pages/Usuario/_usuario-signin';
import { UsuarioForgot } from '../pages/Usuario/_usuario-forgot';

import { RecuperaAcesso } from '../pages/Usuario/_usuario-recupera-acesso';
import { RedefineSenha } from '../pages/Usuario/_usuario-redefine-senha';
import { PrimeiroAcesso } from '../pages/Usuario/_usuario-primeiro-acesso';

import { MobileRecuperaAcesso } from '../pages/Mobile/Usuario/_usuario-recupera-acesso';
import { MobileRedefineSenha } from '../pages/Mobile/Usuario/_usuario-redefine-senha';

import { UsuarioSolicitacao } from '../pages/Usuario/_usuario-solicitacao';

import Home from '../pages/Home';
import { HomePesquisar } from '../pages/Home/_home-pesquisar';
import AppIntegracao from '../pages/Home/AppIntegracao';
import IntegraCatalogo from '../pages/Home/AppIntegracao/Catalogo';
import { IntegraCatalogoToken } from '../pages/Home/AppIntegracao/Token';
import IntegraConsulta from '../pages/Home/AppIntegracao/Consulta';
import IntegraSolicitacao from '../pages/Home/AppIntegracao/Solicitacao';
import Classificacao from '../pages/Home/Classificacao';
import ClassificacaoFiltroPagina from '../pages/Home/ClassificacaoFiltroPagina/indes';
import Carrinho from '../pages/Home/Carrinho';
import Checkout from '../pages/Home/Checkout';

import { Email } from '../pages/Email';
import { WhatsApp } from '../pages/WhatsApp';

import Dashboard from '../pages/Admin';

import Download from '../pages/Admin/Download';

import { EditorCriarAI } from '../pages/EditorAI/criar';
import EditorClassificarAI from '../pages/EditorAI/classificar';
import EditorExtrairAI from '../pages/EditorAI/extrair';
import EditorEditarAI from '../pages/EditorAI/editar';
import EditorAssociarAI from '../pages/EditorAI';

import Editor from '../pages/Editor';
import EditorCriar from '../pages/Editor/criar';
import EditorClassificar from '../pages/Editor/classificar';
import EditorExtrair from '../pages/Editor/extrair';

import { Catalogo } from '../pages/Catalogo';
import { CatalogoDetalhe } from '../pages/Catalogo/detalhe';
import CatalogoEditar from '../pages/Catalogo/editar';
import CatalogoPagina from '../pages/Catalogo/_catalogo-pagina';

import CatalogoItem from '../pages/Catalogo/_catalogo-item';
import CatalogoItemCriar from '../pages/Catalogo/_catalogo-item/criar';
import CatalogoItemEditar from '../pages/Catalogo/_catalogo-item/editar';

import { CatalogoSerie } from '../pages/Catalogo/_catalogo-serie';
import { CatalogoSerieCriar } from '../pages/Catalogo/_catalogo-serie/criar';
import { CatalogoSerieEditar } from '../pages/Catalogo/_catalogo-serie/editar';
import { CatalogoArranjoEditar } from '../pages/Catalogo/_catalogo-arranjo/editar';
import { CatalogoRelacArranjoPagina } from '../pages/Catalogo/_catalogo-arranjo/_arranjo-pagina';
import { CatalogoRelacArranjoItem } from '../pages/Catalogo/_catalogo-arranjo/_arranjo-item';
import { CatalogoRelacConjuntoItemPagina } from '../pages/Catalogo/_catalogo-conjunto';

import { CatalogoConferenciaItens } from '../pages/Catalogo/_catalogo-merge';

import CatalogoNivel from '../pages/Catalogo/_catalogo-nivel';
import CatalogoNivelCriar from '../pages/Catalogo/_catalogo-nivel/criar';
import CatalogoNivelEditar from '../pages/Catalogo/_catalogo-nivel/editar';

import Fabricante from '../pages/Fabricante';
import FabricanteDetalhe from '../pages/Fabricante/detalhe';
import FabricanteCriar from '../pages/Fabricante/criar';
import FabricanteEditar from '../pages/Fabricante/editar';

import { MaterialFabricanteCriar } from '../pages/Fabricante/_material-fabricante/criar';
import { MaterialFabricanteEditar } from '../pages/Fabricante/_material-fabricante/editar';

import Modelo from '../pages/Modelo';
import ModeloCriar from '../pages/Modelo/criar';
import ModeloEditar from '../pages/Modelo/editar';

import { Sugestao } from '../pages/Sugestao';
import { SugestaoEditar } from '../pages/Sugestao/editar';

import { Empresa } from '../pages/Empresa';
import { EmpresaCriar } from '../pages/Empresa/criar';
import { EmpresaEditar } from '../pages/Empresa/editar';
import { EmpresaDetalhe } from '../pages/Empresa/detalhe';

import { EmpresaCatalogo } from '../pages/Empresa/_empresa-catalogo';
import { EmpresaCatalogoCriar } from '../pages/Empresa/_empresa-catalogo/criar';

import { EmpresaCatalogoDetalhe } from '../pages/Empresa/_empresa-catalogo/detalhe';
import { EmpresaCatalogoToken } from '../pages/Empresa/_empresa-catalogo/_catalogo-token-acesso';
import { EmpresaCatalogoTokenCriar } from '../pages/Empresa/_empresa-catalogo/_catalogo-token-acesso/criar';
import { EmpresaCatalogoTokenEditar } from '../pages/Empresa/_empresa-catalogo/_catalogo-token-acesso/editar';
import { EmpresaCatalogoNavegacaoCriar } from '../pages/Empresa/_empresa-catalogo/_catalogo-navegacao/criar';
import EmpresaCatalogoMaterial from '../pages/Empresa/_empresa-catalogo/_catalogo-material';
import EmpresaCatalogoMaterialEditar from '../pages/Empresa/_empresa-catalogo/_catalogo-material/editar';
import { EmpresaSerieLoteCatalogo } from '../pages/Empresa/_empresa-serie-lote';
import { EmpresaSerieLoteCatalogoCriar } from '../pages/Empresa/_empresa-serie-lote/criar';
import { EmpresaSerieLoteCatalogoEditar } from '../pages/Empresa/_empresa-serie-lote/editar';
import EmpresaRelacPaginaSistLoc from '../pages/Empresa/_empresa-catalogo/_catalogo-sistema-local';
import EmpresaRelacPaginaSistLocEditar from '../pages/Empresa/_empresa-catalogo/_catalogo-sistema-local/editar';

import { EmpresaUsuario } from '../pages/Empresa/_empresa-usuario';
import { EmpresaUsuarioCriar } from '../pages/Empresa/_empresa-usuario/criar';
import { EmpresaUsuarioEditar } from '../pages/Empresa/_empresa-usuario/editar';
import { EmpresaUsuarioDetalhe } from '../pages/Empresa/_empresa-usuario/detalhe';
import { EmpresaUsuarioLogin } from '../pages/Empresa/_empresa-usuario/_usuario-login';

import { EmpresaTipoComplemento } from '../pages/Empresa/_empresa-tipo-complemento';
import { EmpresaTipoComplementoCriar } from '../pages/Empresa/_empresa-tipo-complemento/criar';
import { EmpresaTipoComplementoEditar } from '../pages/Empresa/_empresa-tipo-complemento/editar';

import { EmpresaNotificacao } from '../pages/Empresa/_empresa-notificacao';
import { EmpresaNotificacaoEditar } from '../pages/Empresa/_empresa-notificacao/editar';

import { Notificacao } from '../pages/Notificacao';
import { NotificacaoCriar } from '../pages/Notificacao/criar';
import { NotificacaoEditar } from '../pages/Notificacao/editar';

import { EmpresaSugestao } from '../pages/Empresa/_empresa-sugestao';
import { EmpresaSugestaoEditar } from '../pages/Empresa/_empresa-sugestao/editar';

import EmpresaRetirada from '../pages/Empresa/_empresa-retirada';
import EmpresaRetiradaDetalhe from '../pages/Empresa/_empresa-retirada/detalhe';
import EmpresaRetiradaVisualizar from '../pages/Empresa/_empresa-retirada/visualizar';

import EmpresaModeloCriar from '../pages/Empresa/_empresa-modelo/criar';
import EmpresaModeloCarregar from '../pages/Empresa/_empresa-modelo/carregar';

import { EmpresaSistema } from '../pages/Empresa/_empresa-sistema';
import { EmpresaSistemaCriar } from '../pages/Empresa/_empresa-sistema/criar';
import { EmpresaSistemaEditar } from '../pages/Empresa/_empresa-sistema/editar';

import { EmpresaLocal } from '../pages/Empresa/_empresa-local';
import { EmpresaLocalCriar } from '../pages/Empresa/_empresa-local/criar';
import { EmpresaLocalEditar } from '../pages/Empresa/_empresa-local/editar';

import { EmpresaMobilidade } from '../pages/Empresa/_empresa-mobilidade';
import { EmpresaMobilidadeCriar } from '../pages/Empresa/_empresa-mobilidade/criar';
import { EmpresaMobilidadeEditar } from '../pages/Empresa/_empresa-mobilidade/editar';

import { EmpresaParametro } from '../pages/Empresa/_empresa-parametro';
import { EmpresaParametroEditar } from '../pages/Empresa/_empresa-parametro/editar';

import { EmpresaSolicitacao } from '../pages/Empresa/_empresa-solicitacao';

import { EmpresaNavegacaoClassificacao } from '../pages/Empresa/_empresa-navegacao-classificacao';
import { EmpresaNavegacaoClassificacaoCriar } from '../pages/Empresa/_empresa-navegacao-classificacao/criar';
import { EmpresaNavegacaoClassificacaoEditar } from '../pages/Empresa/_empresa-navegacao-classificacao/editar';
import { EmpresaNavegacaoClassificacaoDetalhe } from '../pages/Empresa/_empresa-navegacao-classificacao/detalhe';

import { EmpresaNavegacao } from '../pages/Empresa/_empresa-navegacao-classificacao/_navegacao';
import { EmpresaNavegacaoCriar } from '../pages/Empresa/_empresa-navegacao-classificacao/_navegacao/criar';
import { EmpresaNavegacaoEditar } from '../pages/Empresa/_empresa-navegacao-classificacao/_navegacao/editar';

import Manutencao from '../pages/Manutencao';

/** Rotas personalizadas para mobilidade */
import { MobileHome } from '../pages/Mobile/Home';
import { MobileClassificacao } from '../pages/Mobile/Home/Classificacao';
import { MobileCarrinho } from '../pages/Mobile/Home/Carrinho';
import { MobileCheckout } from '../pages/Mobile/Home/Checkout';

import { MobileUsuarioLogin } from '../pages/Mobile/Usuario/_usuario-login';
import { MobileUsuarioSolicitacao } from '../pages/Mobile/Usuario/_usuario-solicitacao';
import { MobilePrimeiroAcesso } from '../pages/Mobile/Usuario/_usuario-primeiro-acesso';

import { MobileEmpresaSolicitacao } from '../pages/Mobile/Empresa/_empresa-solicitacao';

export default function Routes() {
  const location = useLocation();
  const { background } = location.state || {};
  /** smartphone e tablet */
  const isMobile = useMediaQuery({ query: '(max-width: 820px)' });

  return (
    <>
      <Switch location={background || location}>
        <Route
          path="/"
          exact
          component={() => <Redirect to="/catalogo.partes" />}
        />

        {isMobile ? (
          <Route
            path="/recupera-acesso"
            exact
            component={MobileRecuperaAcesso}
            isLayout={opLayout.AUTH_SIGNIN}
          />
        ) : (
          <Route
            path="/recupera-acesso"
            exact
            component={RecuperaAcesso}
            isLayout={opLayout.AUTH_SIGNIN}
          />
        )}

        {isMobile ? (
          <Route
            path="/redefinicao-senha"
            component={MobileRedefineSenha}
            isLayout={opLayout.AUTH_SIGNIN}
          />
        ) : (
          <Route
            path="/redefinicao-senha"
            component={RedefineSenha}
            isLayout={opLayout.AUTH_SIGNIN}
          />
        )}

        {isMobile ? (
          <Route
            path="/login"
            component={MobileUsuarioLogin}
            isLayout={opLayout.AUTH_LOGIN}
          />
        ) : (
          <Route
            path="/login"
            component={UsuarioLogin}
            isLayout={opLayout.AUTH_LOGIN}
          />
        )}

        {isMobile ? (
          <Route
            path="/primeiro-acesso"
            isPrivate
            isLayout={opLayout.AUTH_REDEFINIR}
            component={MobilePrimeiroAcesso}
          />
        ) : (
          <Route
            path="/primeiro-acesso"
            isPrivate
            isLayout={opLayout.AUTH_REDEFINIR}
            component={PrimeiroAcesso}
          />
        )}

        <Route
          path="/signin"
          component={UsuarioSignin}
          isLayout={opLayout.AUTH_SIGNIN}
        />
        <Route
          path="/forgot"
          component={UsuarioForgot}
          isLayout={opLayout.AUTH_FORGOT}
        />

        <Route path="/app.catalogo" exact component={AppIntegracao} />
        <Route path="/integra.catalogo" exact component={IntegraCatalogo} />
        <Route path="/integra.consulta" exact component={IntegraConsulta} />
        <Route
          path="/integra.solicitacao"
          exact
          component={IntegraSolicitacao}
        />

        <Route path="/catalogo-app" exact component={IntegraCatalogo} />
        <Route path="/consulta-app" exact component={IntegraConsulta} />
        <Route path="/solicitacao-app" exact component={IntegraSolicitacao} />
        <Route path="/catalogo-token" exact component={IntegraCatalogoToken} />

        {isMobile ? (
          <Route
            path="/catalogo.partes"
            exact
            isPrivate
            withPadding={false}
            withFooter={false}
            component={MobileHome}
          />
        ) : (
          <Route
            path="/catalogo.partes"
            exact
            isPrivate
            withPadding={false}
            withFooter={false}
            component={Home}
          />
        )}

        {isMobile ? (
          <Route
            path="/catalogo.partes/:id"
            isPrivate
            component={MobileClassificacao}
          />
        ) : (
          <Route
            path="/catalogo.partes/:id"
            isPrivate
            component={Classificacao}
          />
        )}

        {isMobile ? (
          <Route
            path="/paginas-filtro/catalogo.partes/:id"
            isPrivate
            component={ClassificacaoFiltroPagina}
          />
        ) : (
          <Route
            path="/paginas-filtro/catalogo.partes/:id"
            isPrivate
            component={ClassificacaoFiltroPagina}
          />
        )}

        {isMobile ? (
          <Route path="/carrinho" isPrivate component={MobileCarrinho} />
        ) : (
          <Route path="/carrinho" isPrivate component={Carrinho} />
        )}

        {isMobile ? (
          <Route path="/checkout" isPrivate component={MobileCheckout} />
        ) : (
          <Route path="/checkout" isPrivate component={Checkout} />
        )}

        <Route path="/dashboard" isPrivate isAdmin component={Dashboard} />
        <Route path="/download" isPrivate isAdmin component={Download} />

        <Route path="/edicao/criar" isPrivate isAdmin component={EditorCriar} />
        <Route
          path="/edicao/classificar"
          isPrivate
          isAdmin
          component={EditorClassificar}
        />
        <Route
          path="/edicao/extrair"
          isPrivate
          isAdmin
          component={EditorExtrair}
        />
        <Route path="/edicao/editar" isPrivate isAdmin component={Editor} />

        <Route
          path="/edicao/classificarAI"
          isPrivate
          isAdmin
          isAdminComp
          component={EditorClassificarAI}
        />
        <Route
          path="/edicao/extrairAI"
          isPrivate
          isAdmin
          isAdminComp
          component={EditorExtrairAI}
        />
        <Route
          path="/edicao/editarAI"
          isPrivate
          isAdmin
          isAdminComp
          component={EditorEditarAI}
        />
        <Route
          path="/edicao/associarAI"
          isPrivate
          isAdmin
          isAdminComp
          component={EditorAssociarAI}
        />

        <Route
          path="/fabricante"
          exact
          isPrivate
          isAdmin
          component={Fabricante}
        />
        <Route
          path="/fabricante/detalhe"
          isPrivate
          isAdmin
          component={FabricanteDetalhe}
        />

        <Route path="/modelo" exact isPrivate isAdmin component={Modelo} />

        <Route path="/empresa" exact isPrivate isAdmin component={Empresa} />
        <Route
          path="/empresa/detalhe"
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaDetalhe}
        />
        <Route
          path="/empresa/sistema"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaSistema}
        />
        <Route
          path="/empresa/local"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaLocal}
        />
        <Route
          path="/empresa/mobilidade"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaMobilidade}
        />
        <Route
          path="/empresa/parametro"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaParametro}
        />
        <Route
          path="/empresa/usuario"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaUsuario}
        />
        <Route
          path="/empresa/usuario/login"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaUsuarioLogin}
        />
        <Route
          path="/empresa/tipo-complemento"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaTipoComplemento}
        />
        <Route
          path="/notificacao"
          isPrivate
          isAdmin
          isAdminComp
          component={Notificacao}
        />
        <Route
          path="/sugestao"
          isPrivate
          isAdmin
          isAdminComp
          component={Sugestao}
        />
        <Route
          path="/empresa/catalogo"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaCatalogo}
        />
        <Route
          path="/empresa/catalogo/detalhe"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaCatalogoDetalhe}
        />
        <Route
          path="/empresa/retirada/detalhe"
          exact
          isPrivate
          isAdmin
          component={EmpresaRetiradaDetalhe}
        />
        <Route
          path="/empresa/catalogo/pagina-sistema-local"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaRelacPaginaSistLoc}
        />
        <Route
          path="/empresa/catalogo/material"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaCatalogoMaterial}
        />

        <Route
          path="/empresa/catalogo/token-acesso"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaCatalogoToken}
        />

        <Route
          path="/empresa/retirada"
          exact
          isPrivate
          isAdmin
          component={EmpresaRetirada}
        />

        {isMobile ? (
          <Route
            path="/empresa/solicitacao"
            exact
            isPrivate
            component={MobileEmpresaSolicitacao}
          />
        ) : (
          <Route
            path="/empresa/solicitacao"
            exact
            isPrivate
            component={EmpresaSolicitacao}
          />
        )}

        <Route
          path="/empresa/navegacao-classificacao"
          exact
          isPrivate
          component={EmpresaNavegacaoClassificacao}
        />
        <Route
          path="/empresa/navegacao-classificacao/detalhe"
          exact
          isPrivate
          component={EmpresaNavegacaoClassificacaoDetalhe}
        />

        <Route
          path="/empresa/navegacao-classificacao/navegacao"
          exact
          isPrivate
          component={EmpresaNavegacao}
        />

        <Route
          path="/empresa/notificacao"
          exact
          isPrivate
          component={EmpresaNotificacao}
        />

        <Route
          path="/empresa/sugestao"
          exact
          isPrivate
          component={EmpresaSugestao}
        />

        <Route
          path="/empresa/serie-lote"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaSerieLoteCatalogo}
        />

        <Route
          path="/catalogo/merge"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={CatalogoConferenciaItens}
        />

        <Route
          path="/usuario/detalhe"
          isPrivate
          isAdmin
          component={UsuarioDetalhe}
        />

        <Route path="/catalogo" exact isPrivate isAdmin component={Catalogo} />

        <Route
          path="/catalogo/detalhe"
          isPrivate
          isAdmin
          isAdminComp
          component={CatalogoDetalhe}
        />
        <Route
          path="/catalogo/series"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={CatalogoSerie}
        />
        <Route
          path="/catalogo/pagina"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={CatalogoPagina}
        />
        <Route
          path="/catalogo/itens"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={CatalogoItem}
        />
        <Route
          path="/catalogo/nivel"
          exact
          isPrivate
          isAdmin
          isAdminComp
          component={CatalogoNivel}
        />

        <Route path="/usuario" exact isPrivate isAdmin component={Usuario} />
        <Route
          path="/usuario/perfil"
          exact
          isPrivate
          component={UsuarioPerfil}
        />

        <Route
          path="/empresa/usuario/detalhes"
          isPrivate
          isAdmin
          isAdminComp
          component={EmpresaUsuarioDetalhe}
        />

        {isMobile ? (
          <Route
            path="/usuario/solicitacao"
            exact
            isPrivate
            component={MobileUsuarioSolicitacao}
          />
        ) : (
          <Route
            path="/usuario/solicitacao"
            exact
            isPrivate
            component={UsuarioSolicitacao}
          />
        )}

        <Route path="/manutencao" isPrivate isAdmin component={Manutencao} />
      </Switch>

      {background && (
        <Switch>
          {/* {isMobile ? (
            <Route path="/alterar-senha" exact component={PrimeiroAcesso} />
          ) : (
            <Route
              path="/alterar-senha"
              isPrivate
              isLayout={opLayout.MODAL}
              component={PrimeiroAcesso}
            />
          )} */}

          <Route
            path="/catalogo-pesquisar"
            isPrivate
            isLayout={opLayout.MODAL}
            component={HomePesquisar}
            withModalBtnClose={false}
            withSize={{
              height: '80%',
              width: '40%',
            }}
          />

          {isMobile ? (
            <Route
              path="/enviar-email"
              isPrivate
              isLayout={opLayout.MODAL}
              component={Email}
              withSize={{
                height: '90%',
                width: '95%',
              }}
            />
          ) : (
            <Route
              path="/enviar-email"
              isPrivate
              isLayout={opLayout.MODAL}
              component={Email}
              withSize={{
                height: '80%',
                width: '60%',
              }}
            />
          )}

          {isMobile ? (
            <Route
              path="/whatsapp"
              isPrivate
              isLayout={opLayout.MODAL}
              component={WhatsApp}
              withSize={{
                height: '90%',
                width: '95%',
              }}
            />
          ) : (
            <Route
              path="/whatsapp"
              isPrivate
              isLayout={opLayout.MODAL}
              component={WhatsApp}
              withSize={{
                height: '80%',
                width: '60%',
              }}
            />
          )}

          <Route
            path={[
              '/catalogo/item/importar',
              '/catalogo/nivel/importar',
              '/empresa/menu/importar',
              '/empresa/sistema/importar',
              '/empresa/local/importar',
              '/empresa/mobilidade/importar',
              '/empresa/importar-erp',
            ]}
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            withSize={{
              height: '80%',
              width: '50%',
            }}
            component={Importacao}
          />

          <Route
            path="*/filtro-pesquisa"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            withModalBtnClose={false}
            withSize={{
              height: '80%',
              width: '50%',
            }}
            component={FiltroPesquisa}
          />

          <Route
            path="/edicao/catalogo-criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EditorCriarAI}
            withSize={{
              height: '95%',
              width: '95%',
            }}
          />

          <Route
            path="/catalogo/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={CatalogoEditar}
            withSize={{
              height: '90%',
              width: '90%',
            }}
          />
          <Route
            path="/catalogo/serie-arranjo/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={CatalogoSerieCriar}
          />
          <Route
            path="/catalogo/serie/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            withSize={{
              height: '70%',
              width: '60%',
            }}
            component={CatalogoSerieEditar}
          />
          <Route
            path="/catalogo/arranjo/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={CatalogoArranjoEditar}
            withSize={{
              height: '70%',
              width: '60%',
            }}
          />
          <Route
            path="/catalogo/arranjo/relac-pagina"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={CatalogoRelacArranjoPagina}
            withSize={{
              height: '95%',
              width: '95%',
            }}
          />
          <Route
            path="/catalogo/conjunto"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={CatalogoRelacConjuntoItemPagina}
            withSize={{
              height: '95%',
              width: '95%',
            }}
          />
          <Route
            path="/catalogo/arranjo/relac-item"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={CatalogoRelacArranjoItem}
            withSize={{
              height: '95%',
              width: '95%',
            }}
          />
          <Route
            path="/catalogo/itens/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={CatalogoItemCriar}
          />
          <Route
            path="/catalogo/itens/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={CatalogoItemEditar}
          />
          <Route
            path="/catalogo/nivel/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={CatalogoNivelCriar}
          />
          <Route
            path="/catalogo/nivel/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={CatalogoNivelEditar}
          />
          <Route
            path="/fabricante/criar"
            isPrivate
            isAdmin
            isLayout={opLayout.MODAL}
            component={FabricanteCriar}
          />
          <Route
            path="/fabricante/editar"
            isPrivate
            isAdmin
            isLayout={opLayout.MODAL}
            component={FabricanteEditar}
          />
          <Route
            path="/fabricante/material-fabricante/editar"
            isPrivate
            isAdmin
            isLayout={opLayout.MODAL}
            component={MaterialFabricanteEditar}
          />
          <Route
            path="/fabricante/material-fabricante/criar"
            isPrivate
            isAdmin
            isLayout={opLayout.MODAL}
            component={MaterialFabricanteCriar}
          />
          <Route
            path="/modelo/criar"
            isPrivate
            isAdmin
            isLayout={opLayout.MODAL}
            component={ModeloCriar}
          />
          <Route
            path="/modelo/editar"
            isPrivate
            isAdmin
            isLayout={opLayout.MODAL}
            component={ModeloEditar}
          />
          <Route
            path="/sugestao/editar"
            isPrivate
            isAdmin
            isLayout={opLayout.MODAL}
            component={SugestaoEditar}
          />
          <Route
            path="/empresa/criar"
            isPrivate
            isAdmin
            isLayout={opLayout.MODAL}
            component={EmpresaCriar}
          />
          <Route
            path="/empresa/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaEditar}
          />
          <Route
            path="/empresa/modelo/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaModeloCriar}
          />
          <Route
            path="/empresa/modelo/carregar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaModeloCarregar}
          />

          <Route
            path="/empresa/catalogo/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaCatalogoCriar}
          />

          <Route
            path="/empresa/usuario/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaUsuarioCriar}
          />
          <Route
            path="/empresa/usuario/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaUsuarioEditar}
          />
          <Route
            path="/empresa/usuario/perfil"
            isPrivate
            // isAdmin
            isLayout={opLayout.MODAL}
            component={UsuarioPerfil}
          />
          <Route
            path="/empresa/usuario/trocar"
            isPrivate
            isAdmin
            isLayout={opLayout.MODAL}
            withSize={{
              height: 'auto',
              width: '40%',
            }}
            component={UsuarioTrocarSenha}
          />

          <Route
            path="/usuario/email"
            isPrivate
            isLayout={opLayout.MODAL}
            component={UsuarioEmail}
            withSize={{
              height: '90%',
              width: '90%',
            }}
          />

          <Route
            path="/usuario/whatsapp"
            isPrivate
            isLayout={opLayout.MODAL}
            component={UsuarioWhatsApp}
            withSize={{
              height: '90%',
              width: '90%',
            }}
          />

          <Route
            path="/usuario/trocar"
            exact
            isPrivate
            isLayout={opLayout.MODAL}
            withSize={{
              height: 'auto',
              width: '40%',
            }}
            component={UsuarioTrocarSenha}
          />

          <Route
            path="/empresa/tipo-complemento/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaTipoComplementoCriar}
          />
          <Route
            path="/empresa/tipo-complemento/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaTipoComplementoEditar}
          />

          <Route
            path="/notificacao/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={NotificacaoCriar}
          />
          <Route
            path="/notificacao/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={NotificacaoEditar}
          />

          <Route
            path="/empresa/notificacao/editar"
            exact
            isPrivate
            isLayout={opLayout.MODAL}
            component={EmpresaNotificacaoEditar}
          />

          <Route
            path="/empresa/sugestao/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaSugestaoEditar}
          />

          <Route
            path="/empresa/local/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaLocalCriar}
          />
          <Route
            path="/empresa/local/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaLocalEditar}
          />

          {/* <Route
            path="/empresa/local/importar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            withSize={{
              height: '80%',
              width: '50%',
            }}
            component={Importacao}
          /> */}
          <Route
            path="/empresa/sistema/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaSistemaCriar}
          />
          <Route
            path="/empresa/sistema/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaSistemaEditar}
          />
          {/* <Route
            path="/empresa/sistema/importar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            withSize={{
              height: '80%',
              width: '50%',
            }}
            component={Importacao}
          /> */}

          <Route
            path="/empresa/mobilidade/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaMobilidadeCriar}
          />
          <Route
            path="/empresa/mobilidade/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaMobilidadeEditar}
          />
          {/* <Route
            path="/empresa/mobilidade/importar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            withSize={{
              height: '80%',
              width: '50%',
            }}
            component={Importacao}
          /> */}

          <Route
            path="/empresa/parametro/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaParametroEditar}
          />

          <Route
            path="/empresa/navegacao-classificacao/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaNavegacaoClassificacaoCriar}
          />
          <Route
            path="/empresa/navegacao-classificacao/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaNavegacaoClassificacaoEditar}
          />

          <Route
            path="/empresa/navegacao-classificacao/navegacao/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaNavegacaoCriar}
          />
          <Route
            path="/empresa/navegacao-classificacao/navegacao/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            component={EmpresaNavegacaoEditar}
          />

          <Route
            path="/empresa/retirada/visualizar"
            isPrivate
            isAdmin
            isLayout={opLayout.MODAL}
            component={EmpresaRetiradaVisualizar}
          />

          <Route
            path="/empresa/serie-lote/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            withSize={{
              height: '90%',
              width: '90%',
            }}
            component={EmpresaSerieLoteCatalogoCriar}
          />
          <Route
            path="/empresa/serie-lote/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            withSize={{
              height: '90%',
              width: '90%',
            }}
            component={EmpresaSerieLoteCatalogoEditar}
          />

          <Route
            path="/empresa/catalogo/token-acesso/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            withSize={{
              height: '80%',
              width: '80%',
            }}
            component={EmpresaCatalogoTokenCriar}
          />
          <Route
            path="/empresa/catalogo/token-acesso/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            withSize={{
              height: '80%',
              width: '80%',
            }}
            component={EmpresaCatalogoTokenEditar}
          />

          <Route
            path="/empresa/catalogo/catalogo-navegacao/criar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            withSize={{
              height: '80%',
              width: '80%',
            }}
            component={EmpresaCatalogoNavegacaoCriar}
          />

          <Route
            path="/empresa/catalogo/material/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            withSize={{
              height: '90%',
              width: '90%',
            }}
            component={EmpresaCatalogoMaterialEditar}
          />
          <Route
            path="/empresa/catalogo/pagina-sistema-local/editar"
            isPrivate
            isAdmin
            isAdminComp
            isLayout={opLayout.MODAL}
            withSize={{
              height: '90%',
              width: '90%',
            }}
            component={EmpresaRelacPaginaSistLocEditar}
          />

          <Route
            path="/usuario/criar"
            isPrivate
            isAdmin
            isLayout={opLayout.MODAL}
            component={UsuarioCriar}
          />
          <Route
            path="/usuario/editar"
            isPrivate
            isAdmin
            isLayout={opLayout.MODAL}
            component={UsuarioEditar}
          />
        </Switch>
      )}
    </>
  );
}
