import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    
    /** scrollbar para firefox */
    scrollbar-width: thin;
  }
  
  *:focus {
    outline: 0;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px grey;     
  }

  ::-webkit-scrollbar-thumb {
    background: #79869e;     
  }

  html, body, #app-root {
    display: flex;
    height: 100%;         
    max-height: 100vh;
    width: 100%;  

    /* Desabilita a seleção de texto em toda a plataforma */
    user-select: none;  
  }
  
  body {
    -webkit-font-smoothing: antialiased;
  }
  
  body, input, textarea, button {
    font: 14px 'Roboto', sans-serif;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #2C405A;
    font-family: 'Rubik', sans-serif;    
  }
  
  a {
    text-decoration: none;
  }
  
  ul {
    list-style: none;
  }
  
  button {
    cursor: pointer;
  }

  /* passar para pages/Home/Classificacao/styles.js */
  /* pode utilizar em mais de um lugar o AsyncSelect */
  /* AsyncSelect styles */
  #black-bold-async-select {
    color: #09090b;
    font-size: 16px;
    font-style: normal;
  }

  #grey-bold-async-select {
    color: #27272a;
    font-size: 14px;
  }

  #grey-italic-async-select {
    color: #71717a;
    font-size: 12px;
    font-style: italic;
  }

  #img-async-select {
    width: 80px;
    height: 80px;
    margin-right: 5px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.1);
  }

  .hover-container-async-select {
    position: relative;
    display: inline-block;
    transition-delay: 2.5s visibility;
  }

  .hover-container-async-select .hover-image-async-select {
    visibility: hidden;
    transition-delay: 0.5s;
    position: absolute;
    width: 350px;
    height: 350px;
    top: -50%;
    left: -10%;
    /* box-shadow: -5px -5px 6px rgba(0,0,0,0.1); */
    box-shadow: 0px 3px 6px rgba(0,0,0,0.25);
    border: 2px solid #e4e4e7;
    border-radius: 5px;
    z-index: 1;
    margin-left: 60px;
  }

  .hover-container-async-select:hover .hover-image-async-select {
    visibility: visible;
  }

`;
